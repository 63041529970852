




























import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import HomePage from '@/api/home/homePage';
import DateUtil from '@/utils/DateUtil';

@Component({
  name: 'FrontFrame',
  components: {
  }
})
export default class FrontFrame extends Vue {
  workFlowName: string = 'done';
  list: any[] = [];
  homePage: HomePage = new HomePage();
  param: any = {
    page: {
      pageIndex: 1,
      pageSize: 10
    }
  };

  get noticeList() {
    return this.$store.state.home.todoList;
  }

  // 处理返回数据
  handleResponse(res: any): void {
    this.list = res.data.list.map(node => {
      let d: Date;
      if (this.noticeList.length) {
        let target = this.noticeList.find(a => a.notice.id === node.id);
        if (target) {
          node.noticeType = target.noticeType;
        }
      }
      if (this.workFlowName === 'wait') {
        d = DateUtil.parse(node.startDate);
        node.name = node.performerName;
      } else if (this.workFlowName === 'done') {
        d = DateUtil.parse(node.completedDate);
        node.name = node.completedUserName;
      } else {
        node.name = node.possibilityUserName;
        d = DateUtil.parse(node.startDate);
      }
      let today = new Date();
      if (today.getMonth() === d.getMonth() && today.getDate() === d.getDate() && today.getFullYear() === d.getFullYear()) {
        node.today = true;
      } else {
        node.today = false;
      }
      node.formatedDate = DateUtil.format(d, 'MM/dd HH:mm');
      node.flowType = this.workFlowName;
      return node;
    });
  }

  // 获取左侧列表
  getList(type: string): void {
    this.workFlowName = type;
    switch (this.workFlowName) {
      case 'done':
        this.homePage.getCompleted(this.param).then(this.handleResponse);
        break;
      case 'wait':
        this.homePage.getRuntime(this.param).then(this.handleResponse);
        break;
      case 'possibility':
        this.homePage.getPossibility(this.param).then(this.handleResponse);
        break;
    }
  }

  mounted() {
    // this.getList('wait');
    this.changeWorkFlowName(this.$route.path);
    this.getList(this.workFlowName);
  }

  // 监听事项列表存储
  @Watch('$store.state.home.todoList')
  changeList(newVal) {
    if (newVal.length) {
      this.list.forEach(node => {
        let target = newVal.find(a => a.notice.id === node.id);
        if (target) {
          node.noticeType = target.noticeType;
        }
      });
    }
  }

  @Watch('$route.path')
  onRouteChange() {
    this.changeWorkFlowName(this.$route.path);
    this.getList(this.workFlowName);
  }

  /**
   * 根据路由path改变workFlowName
   * @param newVal
   */
  changeWorkFlowName(newVal) {
    if (newVal.indexOf('/done') != -1) {
      this.workFlowName = 'done';
    } else if (newVal.indexOf('/undo') != -1) {
      this.workFlowName = 'wait';
    } else if (newVal.indexOf('/possibility') != -1) {
      this.workFlowName = 'possibility';
    } else if (newVal.indexOf('/dashboard') != -1) {
      this.workFlowName = 'wait';
    } else if (newVal.indexOf('/create') != -1) {
      this.workFlowName = 'wait';
    }
  }

}
