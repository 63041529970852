var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "front-frame" },
    [
      _c(
        "el-aside",
        { staticClass: "front-aside", attrs: { width: "27.08vw" } },
        [
          _c("div", { staticClass: "title-row" }, [
            _c("div", { staticClass: "my-mission" }, [
              _c("span", [_vm._v("我的任务")]),
            ]),
            _c(
              "div",
              { staticClass: "my-button" },
              [
                _c(
                  "el-button-group",
                  [
                    _c(
                      "el-button",
                      {
                        class: { selected: _vm.workFlowName === "done" },
                        on: {
                          click: function ($event) {
                            return _vm.getList("done")
                          },
                        },
                      },
                      [_vm._v("已办")]
                    ),
                    _c(
                      "el-button",
                      {
                        class: { selected: _vm.workFlowName === "wait" },
                        on: {
                          click: function ($event) {
                            return _vm.getList("wait")
                          },
                        },
                      },
                      [_vm._v("待办")]
                    ),
                    _c(
                      "el-button",
                      {
                        class: { selected: _vm.workFlowName === "possibility" },
                        on: {
                          click: function ($event) {
                            return _vm.getList("possibility")
                          },
                        },
                      },
                      [_vm._v("预知事项")]
                    ),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  { staticClass: "new-icon", attrs: { to: "create" } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/image/front/new.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "list-wrapper" },
            [
              _c("front-list", {
                staticClass: "flow-left",
                attrs: { list: _vm.list },
              }),
            ],
            1
          ),
        ]
      ),
      _c("el-main", { staticClass: "front-main" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }