import HttpHandler from '@/utils/HttpUtil';

interface taskParam {
  page: pageObject;
  applicationIds?: any[];
  title?: string;
}

interface pageObject {
  pageIndex: number;
  pageSize: number;
}

/**
 * 数据项定义API
 * */

export default class HomePage {
  http: any;
  prefix: string;

  constructor() {
    this.http = HttpHandler;
    this.prefix = process.env.VUE_APP_SERVER_CODE;
  }

  // 获取门户首页公司文件列表
  getPortalIndexCompanyFileList(params) {
    let url = '/cbd-system/file/getPortalIndexCompanyFileList';
    return this.http.get(url, params);
  }

  // 获取门户首页日常通知文件列表
  getPortalIndexDailyNotificationFileList(params) {
    let url = '/cbd-system/file/getPortalIndexDailyNotificationFileList';
    return this.http.get(url, params);
  }

  // 获取首页布局页面详情
  getHomeLayoutPage(params) {
    let url = '/cbd-system/layoutPage/getHomeLayoutPage';
    return this.http.get(url, params);
  }

  // 其他引入页面布局详情
  importLayoutDetail(params) {
    let url = '/cbd-system/portalCenterController/detail';
    return this.http.get(url, params);
  }

  //  获取待办
  getRuntime(params: taskParam) {
    let url = process.env.VUE_APP_SERVER_CODE + 'topic/task/query/runtime';
    return this.http.post(url, params);
  }

  //获取发起
  getStart(params: taskParam) {
    let url = process.env.VUE_APP_SERVER_CODE + 'topic/task/query/start';
    return this.http.post(url, params);
  }

  //获取已办
  getCompleted(params: taskParam) {
    // let url = process.env.VUE_APP_SERVER_CODE + 'topic/task/query/completed';
    let url = process.env.VUE_APP_SERVER_CODE + 'topic/task/query/history';
    return this.http.post(url, params);
  }

  //获取办结
  getEnd(params: taskParam) {
    let url = process.env.VUE_APP_SERVER_CODE + 'topic/task/query/end';
    return this.http.post(url, params);
  }

  // 获取转交
  getDelegate(params: taskParam) {
    let url = process.env.VUE_APP_SERVER_CODE + 'topic/task/query/delegate';
    return this.http.post(url, params);
  }

  // 获取抄送
  getCc(params: taskParam) {
    let url = process.env.VUE_APP_SERVER_CODE + 'topic/task/query/cc';
    return this.http.post(url, params);
  }

  // 获取提前预知事项
  getPossibility(params: taskParam) {
    let url = process.env.VUE_APP_SERVER_CODE + 'topic/task/query/possibility';
    return this.http.post(url, params);
  }

  // 跳转表单页
  goAddress(params) {
    let url = '/am/process/task/redirect/go/pc/address';
    return this.http.get(url, params);
  }
  //   获取常用流程
  getFactoryProcess(params: { count: number }) {
    let url = '/river-system/process/instance/query/most/used';
    return this.http.post(url, params);
  }
  //   获取事项监控
  getMonitor(params: taskParam) {
    let url = process.env.VUE_APP_SERVER_CODE + 'topic/task/query/runtime/timeout';
    return this.http.post(url, params);
  }

  //常用系统重定向
  redirectHome(params: taskParam) {
    let url = process.env.VUE_APP_SERVER_CODE + 'app/redirect/home';
    return this.http.get(url, params);
  }
}
